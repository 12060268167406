import { Show, children } from 'solid-js';
import type { Component, JSX } from 'solid-js';
import { cn } from '~/utils/classnames';

type PanelProps = {
  class?: string;
  title?: string | JSX.Element;
  actions?: JSX.Element;
  description?: string;
  children: JSX.Element;
};

export const Panel: Component<PanelProps> = (props) => {
  const [title, description] = [children(() => props.title), children(() => props.description)];

  return (
    <div class={cn('overflow-hidden rounded-lg border bg-white', props.class)}>
      <Show when={title() && !description()}>
        <div class="flex items-center justify-between border-b border-partingline px-6 py-4">
          <h3 class="text-lg font-semibold">{title()}</h3>
          <div>{props.actions}</div>
        </div>
      </Show>
      <Show when={title() && description()}>
        <div class="flex items-center justify-between border-b border-partingline px-6 py-4">
          <div>
            <h3 class="text-lg font-semibold">{title()}</h3>
            <div class="text-xs text-text-level03">{description()}</div>
          </div>
          <div>{props.actions}</div>
        </div>
      </Show>
      {props.children}
    </div>
  );
};
