import { Component, For, Show } from 'solid-js';
import { cn } from '~/utils/classnames';

type LeftStepperProps = {
  class?: string;
  step: number;
  steps: string[];
  img?: string;
};

export const LeftStepper: Component<LeftStepperProps> = (props) => {
  return (
    <div class={cn('flex flex-col px-5 pb-6', props.class)}>
      <div />
      <img class="py-4" src={props.img} />
      <For each={props.steps}>
        {(step, index) => (
          <div class="flex flex-col items-start justify-start">
            <div
              class={cn(
                'relative z-10 flex w-full items-center justify-start gap-2 py-1',
                props.step >= index() ? 'text-primary-color' : 'text-auxiliary-text'
              )}>
              <span
                class={cn(
                  'flex size-6 items-center justify-center rounded-full border bg-white text-sm font-medium',
                  { 'bg-primary-color text-white': props.step > index() },
                  props.step >= index() ? 'border-primary-color' : 'border-auxiliary-text'
                )}>
                {index() + 1}
              </span>
              <span
                class={cn('flex w-fit text-sm font-medium', {
                  'font-semibold': props.step === index(),
                })}>
                {step}
              </span>
            </div>

            <Show when={index() !== props.steps.length - 1}>
              <div
                class={cn('ml-3 h-6 w-0.5 border border-dashed border-auxiliary-text', {
                  'border-primary-color border-solid': props.step - 1 >= index(),
                })}
              />
            </Show>
          </div>
        )}
      </For>
    </div>
  );
};
