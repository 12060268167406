import IconRentalApplication from '~/assets/icons/rentalApplication.png';
import { RENTAL_APPLICATION } from '~/assets/strings';
import { LocalizationButton } from '~/components/layouts/Navigation/localization/LocalizationButton';
import { useLocalization } from '~/hooks/useLocalization';

const RentalApplicationHeader = () => {
  const { t } = useLocalization();

  return (
    <div class="flex w-full  items-center justify-center gap-2 bg-white px-10 py-4 ">
      <div class="flex w-full items-center gap-2">
        <div class="rounded-full bg-primary-color p-2">
          <img src={IconRentalApplication} />
        </div>
        <div class=" text-xl font-semibold">{t(RENTAL_APPLICATION)}</div>
      </div>
      <div class="m-2 flex flex-row justify-end">
        <LocalizationButton />
      </div>
    </div>
  );
};

export default RentalApplicationHeader;
