import { JSX } from 'solid-js';
import { RentalApplication } from '~/pages/rental-application/RentalApplication';

export interface LayoutProps {
  children: JSX.Element;
}

const Layout = (props: LayoutProps) => {
  return <RentalApplication children={props.children} />;
};
export default Layout;
