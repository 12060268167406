import {
  APPLICANT_INFORMATION,
  POTENTIAL_UNITS,
  RENTAL_HISTORY,
  WORK_HISTORY,
  QUESTIONS,
  TERM_AND_CONDITIONS,
  PAYMENT_DETAILS,
  CONFIRM_YOUR_IDENTITY,
} from '~/assets/strings';
import { Presenter } from '~/framework/Presenter';
import { useLocalization } from '~/hooks/useLocalization';
import { User } from '~/state/mainAppState';
import { RentalApplicationSteps } from '~/types/RentaApplicationSteps';

export interface PresentableSteps {
  steps: string[];
  currentStepIndex: number;
}

export class RentalApplicationStepsPresenter extends Presenter<PresentableSteps> {
  protected createModel(state: User): PresentableSteps {
    const { t } = useLocalization();

    const stepMap = new Map<RentalApplicationSteps, string>([
      [RentalApplicationSteps.APPLICANT_INFORMATION, t(APPLICANT_INFORMATION)],
      [RentalApplicationSteps.POTENTIAL_UNITS, t(POTENTIAL_UNITS)],
      [RentalApplicationSteps.RENTAL_HISTORY, t(RENTAL_HISTORY)],
      [RentalApplicationSteps.WORK_HISTORY, t(WORK_HISTORY)],
      [RentalApplicationSteps.QUESTIONS, t(QUESTIONS)],
      [RentalApplicationSteps.TERMS_AND_CONDITIONS, t(TERM_AND_CONDITIONS)],
      [RentalApplicationSteps.PAYMENT, t(PAYMENT_DETAILS)],
      [RentalApplicationSteps.TRANS_UNION_SCREENING, t(CONFIRM_YOUR_IDENTITY)],
    ]);

    const settings = state.rentalApplication.settings;
    const steps: string[] = [stepMap.get(RentalApplicationSteps.APPLICANT_INFORMATION) || ''];
    steps.push(stepMap.get(RentalApplicationSteps.POTENTIAL_UNITS) || '');
    steps.push(stepMap.get(RentalApplicationSteps.RENTAL_HISTORY) || '');
    steps.push(stepMap.get(RentalApplicationSteps.WORK_HISTORY) || '');
    if (settings?.questions && settings.questions.length > 0) {
      steps.push(stepMap.get(RentalApplicationSteps.QUESTIONS) || '');
    }
    steps.push(stepMap.get(RentalApplicationSteps.TERMS_AND_CONDITIONS) || '');
    if (settings?.requiresPayment) {
      steps.push(stepMap.get(RentalApplicationSteps.PAYMENT) || '');
    }
    if (settings?.requiresScreening) {
      steps.push(stepMap.get(RentalApplicationSteps.TRANS_UNION_SCREENING) || '');
    }
    let currentStepIndex = steps.length - 1;
    const currentStep = state.rentalApplication.currentStep;
    if (currentStep && stepMap.has(currentStep)) {
      currentStepIndex = steps.indexOf(stepMap.get(currentStep) || '');
    }
    return { steps, currentStepIndex };
  }
}
