import { useLocation } from '@solidjs/router';
import { JSX, onMount } from 'solid-js';
import IconRentalApplicationBanner from '~/assets/backgrounds/rentalApplicationBanner.png';
import { LeftStepper } from '~/components/common/forms/LeftStepper';
import { Panel } from '~/components/common/panels';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { RentalApplicationStepsPresenter } from '~/presenters/RentalApplicationStepsPresenter';
import { SetCurrentStepUseCase } from '~/use-cases/rental-applications/application-steps/setCurrentStepUseCase';
import RentalApplicationHeader from './RentalApplicationHeader';

export const RentalApplication = (props: { children: JSX.Element }) => {
  const { model: steps } = usePresenter(RentalApplicationStepsPresenter);
  const { execute: setCurrentStep } = useUseCase(SetCurrentStepUseCase);
  const location = useLocation();

  onMount(() => {
    const path = location.pathname.split('/');
    const stepName = path[path.length - 1];
    setCurrentStep(stepName);
  });

  return (
    <div class="flex h-screen flex-col overflow-y-auto bg-inputbox-bg">
      <div class="sticky top-0 z-50">
        <RentalApplicationHeader />
      </div>
      <div class="flex grow items-center justify-center">
        <div class="flex h-full w-11/12 gap-3 py-4">
          <Panel class="hidden h-fit w-[300px] text-2xl md:flex">
            <LeftStepper steps={steps()?.steps || []} step={steps()?.currentStepIndex || 0} img={IconRentalApplicationBanner} />
          </Panel>
          <main class="h-fit w-full overflow-y-auto md:w-3/4">
            <Panel title={steps()?.steps[steps()?.currentStepIndex || 0]} class="mb-14 h-fit w-full">
              {props.children}
            </Panel>
          </main>
        </div>
      </div>
    </div>
  );
};
